import { PropsWithChildren, useMemo, useState } from 'react'
import classNames from 'classnames'
import InfiniteScroll from 'react-infinite-scroll-component'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { PaginationBar } from './PaginationBar'
import { IconSvg } from './IconSvg'

interface Props {
	columns: any[]
	row?: any
	data: any[] | null
	loading?: boolean
	onPageChange?: (...args: any) => any
	totalCount?: number
	pageSize?: number
	currentPage?: number
	noHeader?: boolean
	infinite?: boolean
	noDataText?: any
	noPaging?: boolean
	className?: string
	noBorder?: boolean
	hasNextPage?: boolean
	stickyHeader?: string | null
	borderedTable?: boolean
	hasChildPredicate?: (item: any) => boolean | undefined
}

export function SimpleTable({
	columns,
	row,
	data,
	loading = false,
	onPageChange,
	totalCount = 0,
	pageSize = 50,
	currentPage = 1,
	noHeader = false,
	infinite = false,
	noDataText = 'No data found',
	noPaging = false,
	noBorder = false,
	className,
	hasNextPage,
	stickyHeader,
	borderedTable,
	hasChildPredicate,
}: PropsWithChildren<Props>) {
	const isNoMorePieLoaded = useMemo(() => {
		return !data?.length || totalCount <= data.length || totalCount <= pageSize
	}, [totalCount, pageSize, JSON.stringify(data)])

	return (
		<div className={classNames(className)}>
			<div className="min-w-full align-middle">
				<div
					className={classNames({
						'overflow-auto': !stickyHeader,
					})}
				>
					<InfiniteScroll
						className="!overflow-visible"
						dataLength={data?.length || 0}
						next={() => {
							onPageChange?.(
								infinite && hasNextPage !== null && hasNextPage !== undefined
									? currentPage
									: currentPage + 1
							)
						}}
						hasMore={
							infinite &&
							(hasNextPage !== null && hasNextPage !== undefined ? hasNextPage : !isNoMorePieLoaded)
						}
						loader={
							<div className="flex justify-center items-center py-4">
								<FontAwesomeIcon icon={['fas', 'spinner']} size="lg" spin className="text-white" />
							</div>
						}
					>
						{!noHeader && (
							<div
								className={classNames(
									'bg-primary-background',
									{
										'sticky left-0 z-[2]': stickyHeader,
									},
									stickyHeader
								)}
							>
								<div
									className={classNames(
										'grid grid-cols-12 bg-primary-tile',
										{
											'border border-solid border-[#A88DE033] rounded-t-2xl': borderedTable,
										},
										row?.header?.className
									)}
								>
									{columns.map((column: any, index: number) => (
										<div
											key={index}
											className={classNames(
												`p-4 text-sm text-secondary-text font-normal`,
												column.header?.className
											)}
										>
											{column.header?.formatter ? column.header.formatter() : column.label}
										</div>
									))}
								</div>
							</div>
						)}
						<div>
							{loading && !data?.length ? (
								<div className="flex justify-center items-center py-4">
									<FontAwesomeIcon icon={['fas', 'spinner']} size="lg" spin className="text-white" />
								</div>
							) : data?.length ? (
								<div className={classNames({ 'divide-y divide-white-10': !noBorder })}>
									{data.map((item: any, index: number) => (
										<Row
											key={index}
											index={index}
											className={row?.className}
											formatter={row?.formatter}
											item={item}
											row={row}
											columns={columns}
											hasChild={hasChildPredicate?.(item)}
											childFormatter={row?.childFormatter}
											borderedTable={borderedTable}
											htmlContent={
												<>
													{columns.map((column: any, i: number) => (
														<div
															key={i}
															className={classNames(
																`p-4 font-bold`,
																column.content?.className
															)}
														>
															{column.content?.formatter
																? column.content.formatter(item)
																: item[column.key]}
														</div>
													))}
												</>
											}
										/>
									))}
								</div>
							) : (
								noDataText !== '' && <div className="p-4 text-center">{noDataText}</div>
							)}
						</div>
					</InfiniteScroll>
				</div>
				{!infinite && !isNoMorePieLoaded && !noPaging && (
					<div className="p-4 bg-primary-tile rounded-b-2xl">
						<PaginationBar
							currentPage={currentPage}
							totalCount={totalCount}
							pageSize={pageSize}
							onPageChange={(page: number) => {
								onPageChange?.(page)
							}}
						/>
					</div>
				)}
			</div>
		</div>
	)
}

interface RowProps {
	index: number
	item?: any
	htmlContent?: any
	formatter?: any
	className?: string
	row?: any
	columns?: any[]
	hasChild?: boolean
	childFormatter?: any
	borderedTable?: boolean
}
const Row = ({
	index,
	item,
	htmlContent,
	formatter,
	className,
	row,
	columns,
	hasChild,
	childFormatter,
	borderedTable,
}: RowProps) => {
	const [isChildVisible, setIsChildVisible] = useState(false)

	if (formatter) {
		return formatter(index, item, htmlContent, row)
	}

	return (
		<>
			<div
				className={classNames(
					`hover:bg-white-5 grid grid-cols-12`,
					{
						'border-y-0 border-x border-[#A88DE033] last:rounded-b-2xl': borderedTable,
					},
					className
				)}
			>
				{columns?.map((column: any, i: number) => (
					<div
						key={i}
						className={classNames(`p-4 font-bold`, column.content?.className, {
							'flex items-center gap-2': hasChild && i === 0,
						})}
					>
						{i === 0 && hasChild && (
							<button onClick={() => setIsChildVisible(!isChildVisible)} className="text-[#6A6577]">
								{isChildVisible ? (
									<IconSvg icon="caret-down-fill" />
								) : (
									<IconSvg icon="caret-right-fill" />
								)}
							</button>
						)}

						{column.content?.formatter ? column.content.formatter(item) : item[column.key]}
					</div>
				))}
			</div>

			{hasChild && isChildVisible && childFormatter?.(item)}
		</>
	)
}
